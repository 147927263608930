import logo from './logo.svg';
import Papa from 'papaparse';
import React, { useState, useEffect } from "react";
import './App.css';

function App() {
  const [rows, setRows] = useState([]);
  const [filterTopic, setFilterTopic] = useState("");

  useEffect(() => {
    async function getData() {
      const response = await fetch('techcelerate-mentors.csv')
      const reader = response.body.getReader()
      const result = await reader.read() // raw array
      const decoder = new TextDecoder('utf-8')
      const csv = decoder.decode(result.value) // the csv text
      const results = Papa.parse(csv, { header: true }) // object with { data, errors, meta }
      const rows = results.data // array of objects
      setRows(rows)
    }
    getData()
  }, [])

  return (
    < section class="text-gray-600 body-font px-4" >
      <div class="container px-5 py-16 mx-auto flex flex-col">
        <div class="lg:w-4/6 mx-auto">
          <div class="rounded-lg h-56 w-56 overflow-hidden mx-auto">
            <img alt="content" class="object-cover object-center h-full w-full" src="/otta-mascot.png" />
          </div>
          <div class="flex flex-col text-center w-full">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Amigoals Techcelerate Mentors</h1>
          </div>
        </div>
      </div>
      <div class="relative inline-flex mb-6">
        <svg class="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232"><path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#648299" fill-rule="nonzero" /></svg>
        <select onChange = {(e) => setFilterTopic(e.target.value)} class="border border-gray-300 rounded-full text-gray-600 h-10 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none">
          <option value="">Filter by topics</option>
          <option>DATA SCIENCE</option>
          <option>DATA ANALYTICS</option>
          <option>DATA ENGINEERING</option>
          <option>FRONTEND</option>
          <option>BACKEND</option>
          <option>INFRASTRUCTURE</option>
          <option>SOLUTION ARCHITECT</option>
          <option>PRODUCT MANAGEMENT</option>
          <option>PRODUCT DESIGN</option>
          <option>UI/UX</option>
        </select>
      </div>
      <div class="flex flex-wrap -m-4">
        {rows.length > 0 &&
          rows.filter((row) => row.topics && row.topics.includes(filterTopic)).map((row) => {
            return (
              <div class="xl:w-1/4 md:w-1/2 w-11/12 mx-auto p-4">
                <div class="bg-gray-100 shadow p-6 rounded-lg">
                  <img class="h-44 w-44 rounded-full object-cover object-center mb-6 mx-auto" src={"https://datamigos-prod.s3.ap-southeast-1.amazonaws.com/" + row.profile_picture} alt="content" />
                  <h2 class="text-lg text-gray-900 font-medium title-font">{row.display_name}</h2>
                  <h3 class="tracking-wide text-indigo-500 text-sm font-medium title-font">{row.company_role}</h3>
                  <h3 class="tracking-wide text-indigo-500 text-sm font-bold title-font">{row.company}</h3>
                  <button onClick={() => window.open("https://" + row.linkedin_url)} class="flex mx-auto mt-6 text-white bg-blue-600 border-0 py-2.5 px-8 content-center focus:outline-none hover:bg-blue-700 rounded">
                    LinkedIn
                  </button>
                </div>
              </div>
            )
          })
        }
      </div>
    </section >
  );
}

export default App;
